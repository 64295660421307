import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Navbar } from "../../navbar/Navbar";
import { Footer } from "../../footer/Footer";
import Applyform from "../Applyform/Applyform";
import './jobdetails.css'

export function JobsDetailsOpen() {

    return (
        <>
            <Navbar />

            <div className="job-details">
                <div className="job-details_description">
                    <h2>Open Application</h2>
           

                    <h3>Company Description:</h3>
                    <p>
                        At Soletis, we are passionate about delivering high-quality IT solutions and consultancy services to a diverse range of clients across various sectors. As a leading player in the industry, we strive to provide tailored software solutions that optimize our clients' business processes and drive their growth.
                    </p>

                    <h3>Job Description:</h3>
                    <p>
                        We are always looking for talented and motivated individuals to join our team. If you are passionate about the IT field and believe you can contribute to our mission, we would love to hear from you. Whether your expertise lies in software development, project management, consulting, or any other area, we encourage you to reach out and tell us how you can be a valuable addition to Soletis.
                    </p>

                    <h3>What We Look For:</h3>
                    <ul>
                        <li>Enthusiasm and passion for IT and software solutions</li>
                        <li>A proactive and results-oriented mindset</li>
                        <li>Strong communication and teamwork skills</li>
                        <li>Innovative thinking and problem-solving abilities</li>
                        <li>A desire to grow and develop within our company</li>
                    </ul>

                    <h3>What We Offer:</h3>
                    <ul>
                        <li>A dynamic and collaborative work environment</li>
                        <li>Opportunities for professional development and career growth</li>
                        <li>Competitive salary and benefits package</li>
                        <li>Flexible working hours</li>
                    </ul>

                    <h3>How to Apply:</h3>
                    <p>
                        If you are interested in joining Soletis BV, please send your resume and a cover letter explaining your background, interests, and how you envision contributing to our team to <a href="mailto:jobs@soletis.be">jobs@soletis.be</a>.
                    </p>
                    <p>
                        We look forward to hearing from you and exploring potential opportunities together.
                    </p>

                </div>

                <div className="job-details_apply">
                    <Applyform title="Open Application" />
                </div>
            </div>
            <Footer />
        </>
    );
}
