import React from "react";
import { createRoot } from "react-dom/client"; // Update import statement
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import App from "./App";
import { Contactpage } from "./components/contactpage/ContactPage/Contactpage";
import { Privacy } from "./components/privacy-terms/Privacy";
import { Terms } from "./components/privacy-terms/Terms";
import reportWebVitals from "./reportWebVitals";
import { Jobs } from "./components/jobs/Jobs/Jobs";
import { JobsDetails } from "./components/jobs/Jobdetails/JobsDetails";
import { JobsDetailsOpen } from "./components/jobs/Jobdetails/JobsDetailsOpen";
// import { Cases } from "./components/cases/Cases";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement); // Use createRoot

const RedirectComponentToEnquete = () => {
  React.useEffect(() => {
    window.location.href = "https://forms.office.com/e/3KnBz6ZNhd";
  }, []);

  return null;
};

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/contact" element={<Contactpage />} />
          {/* <Route path="/cases" element={<Cases />} /> */}
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobs/:id" element={<JobsDetails />} />
          <Route path="/jobs/openApplication" element={<JobsDetailsOpen />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/voorwaarden" element={<Terms />} />
          <Route path="/enquete" element={<RedirectComponentToEnquete />} /> {/* Redirect /enquete */}
          {/* Fallback route */}
          <Route path="*" element={<App />} /> 
        </Routes>
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
