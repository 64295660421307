import React from 'react'
import { LandingPage } from './components/LandingPage/LandingPage'
import { HomepageHowWeHelp } from './components/HomepageHowWeHelp/HomepageHowWeHelp'
import { HomepageProducts } from './components/HomepageProducts/HomepageProducts'
import { HomepageMission } from './components/HomepageMission/HomepageMission'
import { HomepageCases } from './components/HomepageCases/HomepageCases'
import { HomepageNumbers } from './components/HomepageNumbers/HomepageNumbers'
import ClientsSlider from './components/ClientsSlider/ClientsSlider'
import HomepageJobs from './components/HomepageJobs/HomepageJobs'
import HomepageKanaalZ from './components/HomepageKanaalZ/HomepageKanaalZ'



export const Homepage = () => {
  return (
    <>
      <LandingPage />
      <ClientsSlider/>
      <HomepageHowWeHelp />
      <HomepageNumbers />
      <HomepageProducts/>
      <HomepageKanaalZ />
      <HomepageMission />
      {/* <HomepageCases/> */}
      <HomepageJobs />
    </>
   
  )
}

