import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, NavLink } from "react-router-dom";
import './LandingPage.css';

export const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="content">
        <h1 className="headline">Analysis, Development, and Connectivity</h1>
        <p className="subheadline">We are the <strong>contrasting</strong> fluid in your organization</p>
        <div className="buttons">
          <NavLink to="/contact" className="button primary-button">
            Contact Us
          </NavLink>
          <NavLink to="/jobs" className="button secondary-button">
            Explore Jobs
          </NavLink>
        </div>
        <Link to={`https://joliencneuvels.be`} target='_blank'><div className='sponsoring'></div></Link>
      </div>
      {/* <div className="scroll-down">
        <FontAwesomeIcon icon={faArrowDown} />
      </div> */}
    </div>
  );
};
