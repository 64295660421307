import React from 'react';
import { Navbar } from '../navbar/Navbar';
import { Footer } from '../footer/Footer';
import './privacy-terms.css';

export const Privacy = () => {
  return (
    <>
      <Navbar />
      <div className="privacy-terms">
        <div className='privacy-terms_title'>
          <h2 >Soletis bvba 26/04/2018 – privacy statement – versie 1.0</h2>
        </div>
        <p>Privacy en bescherming van de persoonsgegevens.</p>
        <p>Als softwareleverancier staan wij in voor de verwerking van heel wat gegevens. Een deel van deze gegevens hebben betrekking op persoonsgegevens en in dit kader delen wij u het volgende mee.</p>
        <p>De persoonsgegevens die wij verwerken hebben betrekking op u in uw hoedanigheid van klant van het kantoor maar ook op u als zakelijke relatie van onze klanten (zoals het geval dat u leverancier of klant bent van onze klant). In elk geval dienen wij u als betrokkene van wie persoonsgegevens door ons verwerkt worden op het volgende te wijzen.</p>
        <h3>1. Verantwoordelijke voor de verwerking van de persoonsgegevens.</h3>
        <p>De verantwoordelijke voor de verwerking van de persoonsgegevens is Soletis bvba. De zetel van de verantwoordelijke is gelegen te Matrouwstraat 33, 9661 Brakel met als het ondernemingsnummer 0878 048 453.</p>
        <p>Voor alle vragen met betrekking tot de bescherming van persoonsgegevens, kunt u steeds terecht bij Christophe Vanderhaeghen via brief op het bovenstaande adres of via e-mail (privacy@soletis.be).</p>
        <h3>2. Doeleinden van de verwerking van persoonsgegevens.</h3>
        <p>We verwerken je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons of aan onze klanten verstrekt.</p>
        <p>Onze website en/of dienstverlening heeft niet de intentie gegevens te verzamelen over onze gebruikers/klanten voor verder gebruik als marketing. Wij houden hoe dan ook geen cookies bij met persoonsgegevens.</p>
        <h3>3. Welke persoonsgegevens en van wie?</h3>
        <p>Hieronder vind je een overzicht van de persoonsgegevens die wij mogelijk verwerken, afhankelijk van het product of dienst die je afneemt:</p>
        <ul>
          <li>Voor- en achternaam</li>
          <li>Geslacht</li>
          <li>Adresgegevens</li>
          <li>Telefoonnummer</li>
          <li>E-mailadres</li>
          <li>IBAN nummer</li>
          <li>Overige persoonsgegevens die je actief verstrekt, bijvoorbeeld door een contract aan te gaan bij onze klant waarvoor wij de software bieden of als gebruiker van deze software.</li>
        </ul>
        <h3>4. Ontvanger van gegevens</h3>
        <p>Overeenkomstig wat voorafgaat en behalve in de mate dat de mededeling van persoonsgegevens aan organisaties of entiteiten wiens tussenkomst als third service providers voor rekening en onder de controle van de verantwoordelijke vereist is om de voormelde doeleinden te verwezenlijken, zal het kantoor de in dit kader verzamelde persoonsgegevens niet meedelen, verkopen, verhuren of uitwisselen met enige andere organisatie of entiteit, tenzij u daar op voorhand van op de hoogte werd gebracht en hiermee uitdrukkelijk mee instemde.</p>
        <p>Het kantoor doet een beroep third service providers:</p>
        <ul>
          <li>Het kantoor maakt gebruik van een externe hosting oplossing</li>
          <li>Het kantoor doet een beroep op externe medewerkers voor het uitvoeren van bepaalde taken of specifieke opdrachten (IT consultants, …)</li>
          {/* Add any additional points here */}
        </ul>
        <p>Het kantoor kan alle maatregelen nemen die nodig zijn om een goed beheer van de website en van haar informaticasysteem te verzekeren.</p>
        <p>Het kantoor kan de persoonsgegevens doorgeven op vraag van elke wettelijk
          bevoegde overheid, of zelfs op eigen initiatief indien ze te goeder trouw van oordeel is
          dat het doorgeven van die inlichtingen nodig is om aan de wetgeving en aan de
          reglementering te voldoen, of om de rechten of de goederen van het kantoor, van haar
          klanten, van haar website en/of van U te verdedigen en/of te beschermen.</p>
        <h3>5. Veiligheidsmaatregelen.</h3>
        <p>Teneinde, in de mate van het mogelijke, de ongeoorloofde toegang tot de in dit kader verzamelde persoonsgegevens te beletten, heeft het kantoor procedures op het gebied van veiligheid en organisatie opgesteld, welke betrekking hebben zowel op het verzamelen van deze gegevens als op hun bewaring. Deze procedures zijn tevens van toepassing op alle verwerkers waar het kantoor een beroep op doet.</p>
        {/* Add any additional points here */}
        <h3>6. Bewaringstermijn</h3>
        <p>6.1. Persoonsgegevens die wij moeten bewaren krachtens de contracttermen van onze klanten.</p>
        <p>6.2. Andere persoonsgegevens worden bewaard gedurende de termijnen zoals voorzien in de toepasselijke wetgeving zoals de boekhoudwetgeving, de fiscale wetgeving, de sociale wetgeving.</p>
        <p>6.3 Na het verstrijken van de voormelde termijnen worden de persoonsgegevens
          gewist, tenzij een andere geldende wetgeving een langere bewaartermijn voorziet.</p>
        {/* Add any additional points here */}
        <h3>7. Rechten van toegang, rectificatie, vergetelheid, gegevensoverdraagbaarheid, bezwaar, niet-profilering en betreffende kennisgeving veiligheidsgebreken</h3>
        <p>7.1. Betreffende de persoonsgegevens die wij moeten bewaren in toepassing van de
          Wet van 18 september 2017.</p>
        <p>Dit betreft de persoonsgegevens van onze cliënten, de lasthebbers en de
          uiteindelijke begunstigden van de cliënten.</p>
        <p>“<strong>Art. 65.</strong> De persoon op wie krachtens deze wet de verwerking van de
          persoonsgegevens van toepassing is, geniet niet van het recht op toegang en de
          rechtzetting van zijn gegevens, noch van het recht om vergeten te worden, op
          gegevensoverdraagbaarheid of om bezwaren aan te voeren, noch van het recht om
          niet geprofileerd te worden, noch van kennisgeving van de veiligheidsgebreken.
          Het recht op toegang van de betrokken persoon tot de persoonsgegevens die hem
          aangaan, wordt onrechtstreeks uitgeoefend, krachtens het artikel 13 van de
          voornoemde wet van 8 december 1992, bij de Commissie voor de Bescherming van
          de Persoonlijke Levenssfeer zoals ingesteld door artikel 23 van dezelfde wet.
          De Commissie voor de bescherming van de persoonlijke levenssfeer deelt uitsluitend
          aan de verzoeker mede dat de nodige verificaties werden verricht en over het resultaat
          daarvan wat de rechtmatigheid van de verwerking in kwestie betreft.
          Deze gegevens kunnen worden meegedeeld aan de verzoeker wanneer de
          Commissie voor de Bescherming van de Persoonlijke Levenssfeer, in samenspraak
          met de CFI en na advies van de verantwoordelijke voor de verwerking, enerzijds
          vaststelt dat de mededeling ervan niet vatbaar is voor bekendmaking van het bestaan
          van een melding van een vermoeden bedoeld in de artikelen 47 en 54, van de
          gevolgen die hieraan werden gegeven of van de uitoefening door de CFI van haar
          recht om bijkomende inlichtingen te vragen op grond van artikel 81, noch vatbaar is
          om de doelstelling van de strijd tegen WG/FT in het gedrang te brengen, en anderzijds
          vaststelt dat de betreffende gegevens betrekking hebben op de verzoeker en door
          onderworpen entiteiten, de CFI of de toezichtautoriteiten worden bijgehouden voor
          toepassing van deze wet.”
          Voor de toepassing van uw rechten inzake uw persoonsgegevens dient u zich dus te
          wenden tot de Gegegevensbeschermingsautoriteit (zie punt 8.)</p>
        <p>Alle andere persoonsgegevens. Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen.
          Daarnaast heb je het recht om je eventuele toestemming voor de
          gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van
          jouw persoonsgegevens door ons en heb je het recht op
          gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen
          om de persoonsgegevens die wij van jou hebben in een computerbestand naar jou of
          een andere door jou genoemde organisatie te sturen.
          Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je
          persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de
          verwerking van jouw persoonsgegevens sturen naar privacy@soletis.be of per post
          naar bovenstaand adres.
          Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou
          een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie
          uw pasfoto, identiteitskaartnummer en rijksregisternummer zwart, dit ter bescherming
          van uw privacy</p>
        {/* Add any additional points here */}
        <h3>8. Klachten</h3>
        <p>Inzake de verwerking van de persoonsgegevens door ons kantoor kan u een klacht indienen bij de nationale toezichthouder (= gegevensbeschermingsautoriteit):</p>
        <p>Commissie voor de bescherming van de persoonlijke levenssfeer</p>
        <p>Drukpersstraat 35, 1000 Brussel</p>
        <p>Tel +32 (0)2 274 48 00</p>
        <p>Fax: +32 (0)2 274 48 35</p>
        <p>E-mail: commission@privacycommission.be</p>
        <p>URL: <a href="https://www.privacycommission.be">https://www.privacycommission.be</a></p>
      </div>
      <Footer />
    </>
  );
};


