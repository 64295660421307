import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet-async";
import "../src/components/cookieconsent/cookieConsent.css"; // Import the CSS file for cookie consent
import "./App.css";
import { Footer } from "./components/footer/Footer";
import { Homepage } from "./components/homepage/Homepage";
import { Navbar } from "./components/navbar/Navbar";

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    // Check if cookies have been accepted from local storage
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setCookiesAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    // Handle accept action here
    console.log("User accepted cookies");
    localStorage.setItem("cookiesAccepted", true);
    setCookiesAccepted(true);
  };

  const handleDecline = () => {
    // Handle decline action here
    console.log(`Cookie action: ${cookiesAccepted}`);
  };

  return (
    <>
      <Helmet>
        <title>Soletis - Software Written To Match Your Company</title>
        <meta
          name="description"
          content="Soletis provides tailor-made software solutions to enhance your business operations. Click here to discover how our bespoke software can streamline your processes and improve efficiency."
        />
        {/* Open Graph Tags */}
        <meta property="og:title" content="Soletis" />
        <meta
          property="og:description"
          content="Soletis provides tailor-made software solutions to enhance your business operations. Click here to discover how our bespoke software can streamline your processes and improve efficiency."
        />
        <meta property="og:image" content="/Soletis_logo.png" />
        <meta property="og:url" content="https://soletis.eu" />
        <meta property="og:site_name" content="Soletis" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Soletis" />
        <meta
          name="twitter:description"
          content="Soletis provides tailor-made software solutions to enhance your business operations. Click here to discover how our bespoke software can streamline your processes and improve efficiency."
        />
        <meta name="twitter:image" content="/Soletis_logo.png" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://soletis.eu" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Soletis",
              "url": "https://soletis.eu",
              "logo": "/Soletis_logo.png"
            }
          `}
        </script>

        {/* Viewport Configuration */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <Navbar />
      <Homepage />
      <Footer />
      <CookieConsent
        location="bottom"
        cookieName="SoletisCookies"
        enableDeclineButton
        expires={365}
        // overlay
        style={{
          textAlign: "center",
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          color: "#fff",
          padding: "0",
          paddingBottom: "30px",
          paddingTop: "30px",
          fontSize: "1em",
          lineHeight: "1.5",
          alignItems: "center",
          justifyContent: "center",
          height: "max-content",
          minHeight: "7vh",
        }}
        buttonStyle={{
          color: "#fff",
          backgroundColor: "#27AAE1",
          borderRadius: "200px",
          padding: "15px 20px",
          width: "10rem",
          border: "none",
          margin: "0 10px",
          cursor: "pointer",
          fontWeight: "900",
          fontSize: "1em",
          transition: "1s",
        }}
        declineButtonStyle={{
          color: "#fff",
          backgroundColor: "#fa3a1a",
          borderRadius: "200px",
          width: "10rem",
          border: "none",
          margin: "0 10px",
          cursor: "pointer",
          fontWeight: "900",
          fontSize: "1em",
          marginBottom: "20px",
          padding: "15px 20px",
          transition: "1s",
        }}
        onAccept={handleAccept}
        onDecline={handleDecline}
      >
        This website uses cookies to improve your experience and provide
        personalized content. By continuing to use this site, you agree to our{" "}
        <a
          href="/privacy"
          style={{ color: "#fff", textDecoration: "underline" }}
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="/terms"
          style={{ color: "#fff", textDecoration: "underline" }}
        >
          Terms of Service
        </a>
        . If you decline, some features of this site may not function as
        intended.
        <div></div>
      </CookieConsent>

      {/* Add a class to the body to disable scrolling if cookies have not been accepted
      <style>
        {`
          body {
            overflow: ${cookiesAccepted ? "auto" : "hidden"};
          }
        `}
      </style> */}
    </>
  );
}

export default App;
