import React, { useEffect, useRef, useState } from "react";
import "./HomepageMission.css";

const missionData = [
  {
    title: "Happiness",
    text: "Our primary and foremost objective is to foster contented employees and satisfied customers.",
  },
  {
    title: "Keep an open mind",
    text: "Regardless of the organization's size or location, various industries such as insurance, automotive, administration, and accountancy...",
  },
  {
    title: "Alignment",
    text: "By facilitating communication and alignment among customers, business professionals, and IT staff, we connect the dots effectively.",
  },
  {
    title: "Optimize",
    text: "We strive to improve processes, prioritize quality, and empower our team for a happier and more fulfilling work experience.",
  },
  {
    title: "Crystal clear",
    text: "We're passionate about making IT/software accessible and cost-effective.",
  },
];

export const HomepageMission = () => {
  const missionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Disconnect the observer once the component is visible
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Trigger when at least 50% of the element is visible
      }
    );

    observer.observe(missionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const shuffleArray = (array) => {
    let currentIndex = array.length;
    let temporaryValue, randomIndex;


    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;


      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  return (
    <div className={`homepageMission_component ${isVisible ? "visible" : ""}`} ref={missionRef}>
      <h2 className="homepageMission_title">Our Mission</h2>
      <div className="homepageMission_grid">
        {shuffleArray(missionData).map((mission, index) => (
          <div
            key={index}
            className={`homepageMission_info ${isVisible ? "fadeInSlideUp" : ""}`}
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <div className="homepageMission_infoTitle">{mission.title}</div>
            <div className="homepageMission_infoText">{mission.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
