import React, { useState, useEffect } from 'react';

const TypingAnimation = ({ texts, typingSpeed = 50, pauseTime = 1000 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [textIndex, setTextIndex] = useState(-1); // Initialize with -1
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    // Reset state when textIndex changes
    setDisplayedText('');
    setCharIndex(0);
  }, [textIndex]);

  useEffect(() => {
    if (textIndex >= 0) { // Check if textIndex is valid
      if (isTyping) {
        if (charIndex < texts[textIndex].length) {
          setTimeout(() => {
            setDisplayedText(prev => prev + texts[textIndex][charIndex]);
            setCharIndex(prev => prev + 1);
          }, typingSpeed);
        } else {
          setTimeout(() => setIsTyping(false), pauseTime);
        }
      } else {
        if (charIndex > 0) {
          setTimeout(() => {
            setDisplayedText(prev => prev.slice(0, -1));
            setCharIndex(prev => prev - 1);
          }, typingSpeed);
        } else {
          setIsTyping(true);
          setTextIndex(prev => (prev + 1) % texts.length);
        }
      }
    }
  }, [charIndex, isTyping, texts, typingSpeed, pauseTime, textIndex]);

  useEffect(() => {
    // Initialize displayedText with an empty string for the first text
    setDisplayedText('');
    // Start typing by setting textIndex to 0 after mounting
    setTextIndex(0);
  }, []);

  return <span>{displayedText}</span>;
};

export default TypingAnimation;
