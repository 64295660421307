import React, { useEffect, useRef, useState } from 'react';
import './homepageKanaalZ.css';

export default function HomepageKanaalZ() {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={`homepageKanaalZ_container ${isVisible ? "visible" : ""}`} ref={containerRef}>
     
      <div className={`homepageKanaalZ_video ${isVisible ? "slideInLeft" : ""}`}>
        <iframe 
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/tuCNXEjnYYs" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
       <div className={`homepageKanaalZ_text ${isVisible ? "slideInRight" : ""}`}>
        <h2>We Were on TV!</h2>
        <p className="homepageKanaalZ_description">
          We were recently featured on TV, showcasing our innovative solutions and amazing team at <strong>Soletis bv</strong>!
          Watch the segment and see why we're making headlines.
        </p>
      </div>
      <div className="homepageKanaalZ_Liquid-2"></div>
    </div>
  );
}
