import React, { useEffect, useState } from "react";
import "./Contactpage.css";
import { Navbar } from "../../navbar/Navbar";
import { Footer } from "../../footer/Footer";
import { Contactform } from "./Contactform";
import TypingAnimation from './TypingAnimation';

export const Contactpage = () => {
  const [showBelgiumOffice, setShowBelgiumOffice] = useState(false);
  const [showSerbiaOffice, setShowSerbiaOffice] = useState(false);

  useEffect(() => {
    const domain = window.location.hostname.split('.').pop();
    const countryCodes = ['be', 'rs'];
    const countryCode = domain.slice(-2);

    if (countryCodes.includes(countryCode)) {
      setShowBelgiumOffice(countryCode === 'be');
      setShowSerbiaOffice(countryCode === 'rs');
    } else {
      setShowBelgiumOffice(true);
      setShowSerbiaOffice(false);
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="contactPage">
        <div className="contactPage_info">
          <h2>
            Let's explore how Soletis 
            <br />
            <span className="typing-animation">
              <TypingAnimation
                texts={[
                  'works for you',
                  'elevates your business',
                  'solves your challenges'
                ]}
              />
            </span>
          </h2>

          {showBelgiumOffice && (
            <div className="contactPage_infoDetails">
              <div className="contactPage_infoDetailsTitle">
                <div className="contactPage_infoDetails_div">
                  <div>Phone:</div>
                  <div>+32 (0) 9 310 61 36</div>
                </div>
                <div className="contactPage_infoDetails_div">
                  <div>Mobile:</div>
                  <div>+32 (0) 486 05 73 98</div>
                </div>
                <div className="contactPage_infoDetails_div">
                  <div>Email:</div>
                  <div>info@soletis.be</div>
                </div>
                <div className="contactPage_infoDetails_div">
                  <div>Office Belgium:</div>
                  <div>Steenweg Deinze 150 bus 25 - 9810 Nazareth (Gent)</div>
                </div>
              </div>
              <iframe
                title="Belgium Office Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.881448787939!2d4.469799215927887!3d50.50387627947722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c36ef0ad62d85b%3A0x924eaf098a0c304f!2sSteenweg%20Deinze%20150%2C%209810%20Nazareth!5e0!3m2!1sen!2sbe!4v1621409164332!5m2!1sen!2sbe"
                width="300"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          )}
          {showSerbiaOffice && (
            <div className="contactPage_infoDetails">
              <div className="contactPage_infoDetailsTitle">
                <div className="contactPage_infoDetails_div">
                  <div>Phone:</div>
                  <div>+381 26 410 3226</div>
                </div>
                <div className="contactPage_infoDetails_div">
                  <div>Email:</div>
                  <div>info@soletis.rs</div>
                </div>
                <div className="contactPage_infoDetails_div">
                  <div>Office Serbia:</div>
                  <div>Đure Jakšića 6, Smederevo 11300</div>
                </div>
              </div>
              <iframe
                title="Serbia Office Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2839.441600861263!2d20.4467113155189!3d44.787657979098756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475ec5fe6ad2ab43%3A0xf6f37e06597e6180!2s%C4%90ure%20Jak%C5%A1i%C4%87a%206%2C%2013000%20Smederevo!5e0!3m2!1sen!2srs!4v1621409195134!5m2!1sen!2srs"
                width="300"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          )}
        </div>

        <div className="contactPage_form">
          <div className="contactPage_formIntro">
            We're just one click away to help you take your brand or product
            from great to incredible. Contact us via the form to let us know you
            are ready to bring your business to another level, or tell us about
            your bugs. Either way, we'd love to get to know you.
          </div>
          <Contactform />
        </div>
      </div>
      <Footer />
    </>
  );
};
