import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import './HomepageNumbers.css';

const AnimatedStat = ({ value, label, showPlus }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '50% 0%',
  });

  const { number } = useSpring({
    from: { number: 0 },
    to: { number: inView ? value : 0 },
    config: { duration: 2000 },
  });

  return (
    <div ref={ref} className="animated-stat">
      <div className='animated-stat-div'>
      <animated.span>{number.to((val) => Math.floor(val))}</animated.span>
      {showPlus && <span>+</span>}
      </div>
      <p>{label}</p>
    </div>
  );
};

export const HomepageNumbers = () => {
  return (
    <div className="homepage-numbers-container">
      <AnimatedStat value={25} label="Years of Experience" />
      <AnimatedStat value={200} label="Clients Served" showPlus />
      <AnimatedStat value={400} label="Projects Done" showPlus />
      <AnimatedStat value={99} label="Success Rate %" />
      <AnimatedStat value={100} label="ROI%" />
    </div>
  );
};