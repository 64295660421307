import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from "react-router-dom";
import './homepageJobs.css';

export default function HomepageJobs() {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={`homepageJobs_container ${isVisible ? "visible" : ""}`} ref={containerRef}>
      <div className={`homepageJobs_left-side ${isVisible ? "fadeInRight" : ""}`}>
        <h2>Join Us</h2>
        <p className="homepageJobs_description">
          Love crafting awesome software and hanging out with amazing people? That's the vibe at <strong>Soletis bv</strong>!
          We're searching high and low for talented individuals who are ready to join the party, innovate, and
          make some magic happen. Apply now and let's kickstart this epic adventure together!
        </p>
        <NavLink to="/jobs" className="homepageJobs_join-us-button">
          Apply now
        </NavLink>
      </div>
      <div className={`homepageJobs_right-side ${isVisible ? "fadeInLeft" : ""}`}>
        <div className="homepageJobs_image-placeholder"></div>
      </div>
      <div className="homepageJobs_Liquid-2"></div>
    </div>
  );
}
