import React, { useState, useEffect } from 'react';
import './clientsSlider.css';
import clients from './clients.json';

export default function ClientsSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSet, setCurrentSet] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 3) % clients.length);
    }, 5000); // Change images every 5 seconds

    return () => clearInterval(interval);
  }, [clients.length]);

  useEffect(() => {
    const getNextSet = () => {
      const firstIndex = currentIndex % clients.length;
      const secondIndex = (currentIndex + 1) % clients.length;
      const thirdIndex = (currentIndex + 2) % clients.length;
      return [clients[firstIndex], clients[secondIndex], clients[thirdIndex]];
    };
    setCurrentSet(getNextSet());
  }, [currentIndex, clients]);

  return (
    <div className="clients-slider">
      <div className="client-wrapper">
        <div className="client-set">
          {currentSet.map((client) => (
            <div key={client.id} className="client-item">
              <img src={client.image} alt={client.title} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
