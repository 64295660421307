import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Popup from './Popup';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingAnimation from './LoadingAnimation'; // Import the LoadingAnimation component
import './applyform.css';

const Applyform = ({ title }) => {
  const [formData, setFormData] = useState({
    title: title,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    motivation: '',
  });

  const [showPopup, setShowPopup] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaFailed, setRecaptchaFailed] = useState(false);
  const [workItems, setWorkItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // State for form submission loading

  useEffect(() => {
    const fetchRandomWorkItems = async () => {
      try {
        const responseIds = await fetch(
          `${process.env.REACT_APP_RECAPTCHA_SITE_ADRESS}/wit/wiql?api-version=6.0`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${btoa(`Basic:${process.env.REACT_APP_PATKEY}`)}`,
            },
            body: JSON.stringify({
              query: "Select [System.Id] From WorkItems Where [System.WorkItemType] = 'Vacature'",
            }),
          }
        );

        if (!responseIds.ok) {
          throw new Error("Failed to fetch work item IDs");
        }

        const dataIds = await responseIds.json();
        const ids = dataIds.workItems.map((item) => item.id);
        const responseWorkItems = await fetch(
          `${process.env.REACT_APP_RECAPTCHA_SITE_ADRESS}/wit/workitems?ids=${ids.join(",")}&api-version=7.1-preview.3`,
          {
            headers: {
              Authorization: `Basic ${btoa(`Basic:${process.env.REACT_APP_PATKEY}`)}`,
            },
          }
        );

        if (!responseWorkItems.ok) {
          throw new Error("Failed to fetch work items");
        }

        const dataWorkItems = await responseWorkItems.json();
        setWorkItems(shuffleArray(dataWorkItems.value).slice(0, 2)); // Shuffle and slice work items
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching random work items:", error);
        setIsLoading(false);
      }
    };

    fetchRandomWorkItems();
  }, []); // Empty dependency array to ensure it runs only once

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  const getCountry = (item) => {
    const countryCode = item.fields["Custom.Country"].toLowerCase();
    if (countryCode.includes("belgium")) {
      return "Belgium";
    } else if (countryCode.includes("serbia")) {
      return "Serbia";
    } else {
      return "Unknown";
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaFailed(false); // Reset recaptchaFailed state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error('reCAPTCHA validation failed.');
      setRecaptchaFailed(true); // Set recaptchaFailed state
      return;
    }

    setIsSubmitting(true); // Start form submission loading

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/apply-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, recaptchaValue }),
      });

      const data = await response.json();

      if (data.success) {
        setShowPopup(true);
      } else {
        console.error('Error submitting form:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false); // Stop form submission loading
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    // Optionally reset form fields or perform other actions upon closing the popup
  };

  return (
    <>
      {isSubmitting && <LoadingAnimation />} {/* Render LoadingAnimation when submitting */}
      <div className='applyform'>
        <div className='applyform_label'>Apply Now!</div>
        <form className="applyform_form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="firstName">First name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              placeholder='First Name...'
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="lastName">Last name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              placeholder='Last Name...'
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              placeholder='Email...'
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="phoneNumber">Phone number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              placeholder='Phone Number...'
              onChange={handleChange}
            />
          </div>

          <div className="input-group-motivation">
            <label htmlFor="motivation">Motivation*</label>
            <textarea
              id="motivation"
              name="motivation"
              value={formData.motivation}
              placeholder='Tell us about your motivation...'
              onChange={handleChange}
              required
            />
          </div>

          <p className="privacy-text">By submitting, you agree to our <NavLink to="/privacy">privacy</NavLink>.</p>

          {recaptchaFailed && (
            <p className='reCAPTCHA_notDone'>Please complete the reCAPTCHA challenge before submitting.</p>
          )}

          <ReCAPTCHA
            className="applyform_reCAPTCHA"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />

          <button type="submit">Submit</button>
        </form>
        <div className='applyform_label'>More job offers:</div>
        <div className="applyform_moreOffers">
          {isLoading ? (
            <div className="applyform_loadingAnimation_container">
              <div className="applyform_loadingAnimation"></div>
            </div>
          ) : (
            <ul className="applyform_list_ul">
              {workItems.map((item) => (
                <div className="applyform_list" key={item.id}>
                  <Link to={`/jobs/${item.id}`} className="applyform_list_item">
                    <div className="applyform_list_itemUpper">
                      <div className="applyform_list_item_info">
                        {item.fields["System.Title"]}
                      </div>
                      <div className="applyform_list_itemBottom">
                        {getCountry(item)}
                      </div>
                    </div>
                    <div className="applyform_list_itemArrow">
                      <svg width="26" height="30" viewBox="0 0 26 30">
                        <path
                          id="Polygon_11"
                          data-name="Polygon 11"
                          d="M10.669,7.507a5,5,0,0,1,8.662,0L25.674,18.5A5,5,0,0,1,21.343,26H8.657a5,5,0,0,1-4.331-7.5Z"
                          transform="translate(26) rotate(90)"
                          fill="#faa61a"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              ))}
            </ul>
          )}
        </div>
      </div>

      {showPopup && (
        <Popup>
          <div className="popup-content">
            <h2>Thank you!</h2>
            <p>We will review your application as soon as possible!</p>
            <NavLink to="/" onClick={handlePopupClose}>Back</NavLink>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Applyform;
