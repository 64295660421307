import React, { useEffect, useRef, useState } from 'react';
import './HomepageHowWeHelp.css';

export const HomepageHowWeHelp = () => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div className='howWeHelp_component'>
      <div
        ref={containerRef}
        className={`howWeHelp_container ${isVisible ? 'visible' : ''}`}
      >
        <div className='howWeHelp_leftSide'>
          <h2 className='howWeHelp_title'>This is how we help</h2>
          <div className='howWeHelp_image'></div>
        </div>

        <div className='howWeHelp_content'>
          <div className='howWeHelp_info'>
            <div className='howWeHelp_infoTop'>
              <div className='howWeHelp_infoTopLeft'></div>
              <div className='howWeHelp_infoTopRight'>
                <div className='howWeHelp_infoTopRightTitle'>Analysis / Monitoring</div>
                <div className='howWeHelp_infoTopRightSubtitle'>Custom software</div>
              </div>
            </div>
            <div className='howWeHelp_infoBottom'>
              Various software solutions can befuddle even seasoned entrepreneurs. We untangle the best fit for your company’s success.
            </div>
          </div>
          <div className='howWeHelp_info'>
            <div className='howWeHelp_infoTop'>
              <div className='howWeHelp_infoTopLeft'></div>
              <div className='howWeHelp_infoTopRight'>
                <div className='howWeHelp_infoTopRightTitle'>Development / Consultancy</div>
                <div className='howWeHelp_infoTopRightSubtitle'>Advices, Analysis, Programming</div>
              </div>
            </div>
            <div className='howWeHelp_infoBottom'>
              Our priority: Quality connections with current and future clients. We tailor solutions to their needs, offering expertise and flexibility, be it standard or custom products.
            </div>
          </div>
          <div className='howWeHelp_info'>
            <div className='howWeHelp_infoTop'>
              <div className='howWeHelp_infoTopLeft'></div>
              <div className='howWeHelp_infoTopRight'>
                <div className='howWeHelp_infoTopRightTitle'>Efficiency / Connectivity</div>
                <div className='howWeHelp_infoTopRightSubtitle'>Software packages from Soletis</div>
              </div>
            </div>
            <div className='howWeHelp_infoBottom'>
              Our portfolio includes diverse tools like STAM© (Standard Admin Management), Reporting, financial solutions, and more.
            </div>
            
          </div>
          <div className='howWeHelp_info'>
            <div className='howWeHelp_infoTop'>
              <div className='howWeHelp_infoTopLeft'></div>
              <div className='howWeHelp_infoTopRight'>
                <div className='howWeHelp_infoTopRightTitle'>Cybersecurity / GDPR</div>
                <div className='howWeHelp_infoTopRightSubtitle'>Protect your business and data</div>
              </div>
            </div>
            <div className='howWeHelp_infoBottom'>
            At Soletis, we prioritize cybersecurity and data protection. Our experts provide guidance on implementing robust security measures and ensure compliance with GDPR regulations.
            </div>
          </div>
        </div>
        <div className='howWeHelp_Liquid-1'></div>
        <div className='howWeHelp_Liquid-2'></div>
      </div>
    </div>
  );
};
