// Contactform.jsx
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { countries } from 'countries-list';
import { NavLink } from "react-router-dom";
import Popup from './Popup';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingAnimation from './LoadingAnimation'; // Import the Loading Animation component

const countryOptions = Object.entries(countries).map(([code, info]) => ({
  value: code,
  label: info.name,
}));

const defaultCountryCode = 'BE';

export const Contactform = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    country: defaultCountryCode,
    email: '',
    phoneNumber: '',
    additionalInfo: '',
  });

  const [showPopup, setShowPopup] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaFailed, setRecaptchaFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading animation

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaFailed(false); // Reset recaptchaFailed state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error('reCAPTCHA validation failed.');
      setRecaptchaFailed(true); // Set recaptchaFailed state
      return;
    }

    setIsLoading(true); // Show loading animation

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, recaptchaValue }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        console.log('Form submitted successfully');
        setShowPopup(true);
      } else {
        console.error('Error submitting form:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false); // Hide loading animation
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      country: selectedOption.value,
    }));
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showPopup && e.key === 'Enter') {
        e.preventDefault();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [showPopup]);

  return (
    <>
      <form className="contactform_form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="firstName">First name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            placeholder='First Name...'
            onChange={handleChange}
          />
        </div>

        <div className="input-group">
          <label htmlFor="lastName">Last name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            placeholder='Last Name...'
            onChange={handleChange}
          />
        </div>

        <div className="input-group">
          <label htmlFor="companyName">Company name*</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            placeholder='Company Name...'
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="country">Country</label>
          <Select
            id="country"
            name="country"
            value={countryOptions.find((c) => c.value === formData.country)}
            options={countryOptions}
            onChange={handleCountryChange}
            isSearchable={true} // Enable search
          />
        </div>

        <div className="input-group special-input-group">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            placeholder='Email...'
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-group special-input-group">
          <label htmlFor="phoneNumber">Phone number</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            placeholder='Phone Number...'
            onChange={handleChange}
          />
        </div>

        <div className="input-group special-input-group">
          <label htmlFor="additionalInfo">Additional info*</label>
          <textarea
            id="additionalInfo"
            name="additionalInfo"
            value={formData.additionalInfo}
            placeholder='Tell us about your request...'
            onChange={handleChange}
            required
          />
        </div>

        <p className="privacy-text">By submitting, you agree to our <NavLink to="/privacy">privacy</NavLink>.</p>

        {recaptchaFailed && (
          <p className='reCAPTCHA_notDone'>Please complete the reCAPTCHA challenge before submitting.</p>
        )}

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleRecaptchaChange}
        />

        <button type="submit">Submit</button>
      </form>

      {isLoading && <LoadingAnimation />} {/* Show loading animation if isLoading is true */}

      {showPopup && (
        <Popup>
          <div className="popup-content">
            <h2>Thank you!</h2>
            <p>We will help you as soon as possible!</p>
            <NavLink to="/">Back</NavLink>
          </div>
        </Popup>
      )}
    </>
  );
};
