import React from 'react';
import { Navbar } from '../navbar/Navbar';
import { Footer } from '../footer/Footer';
import './privacy-terms.css';

export const Terms = () => {
    return (

        <>
            <Navbar />
            <div className="privacy-terms">
        <div className='privacy-terms_title'>
                <h2>Algemene voorwaarden</h2>
                </div>
                
                <p>De volgende algemene voorwaarden van de onderneming Soletis bv met maatschappelijke zetel te Matrouwstraat 33 in 9661 Parike, hierna vernoemd als de verkoper of de opdrachtnemer, zijn van toepassing op al zijn klanten, hierna vernoemd als de klant of de koper, waarmee hij handel drijft. De voorwaarden gelden voor alle officiële documenten van de verkoper.</p>
                <h3>Artikel 1:</h3>
                <p>De koper aanvaardt dat de bestelling is gebeurd onder de algemene voorwaarden van de verkoper, voor de producten en diensten van de verkoper, er kennis van genomen te hebben en ermee in te stemmen, tenzij andersluidende voorwaarden werden bedongen.</p>
                <h3>Artikel 2:</h3>
                <p>Het contract voor de producten en diensten van de verkoper treedt in werking op de datum van ondertekening door alle beide partijen.</p>
                <h3>Artikel 3:</h3>
                <p>De BTW is voor rekening van de koper.</p>
                <h3>Artikel 4:</h3>
                <p>De factuur geldt als bewijs van aankoopdatum en is tevens de startdatum van uw garantie.</p>
                <h3>Artikel 5:</h3>
                <p>De factuur geldt als enig garantiedocument voor de bij Soletis bv aangekochte producten. Op elke geleverde dienst of product geldt een garantieperiode van één jaar, tenzij anders vermeld op de factuur. De factuur is tevens een garantiebewijs.</p>
                <h3>Artikel 6:</h3>
                <p>Alle facturen zijn betaalbaar binnen een betalingstermijn van 7 (zeven) kalenderdagen, tenzij andere voorwaarden werden bedongen. Bij het overschrijden van deze termijn zal automatisch, en zonder dat hiervoor enige ingebrekestelling vereist is, door de koper van rechtswege een verwijlintrest verschuldigd zijn vanaf de vervaldag van de factuur, ten belope van 9%. Soletis bv behoudt zich het recht om bij niet betaling van de factuur op de vervaldatum de diensten te weigeren of de producten niet te leveren.</p>
                <h3>Artikel 7:</h3>
                <p>Indien de koper in gebreke blijft het factuurtotaal te betalen, ook na het verstrijken van de vervaldatum van de factuur, aanvaarden beide partijen dat benevens de betaling van de verwijlintrest, in hoofde van de verkoper, een bijkomende schade ontstaat wanneer deze verkoper de betaling niet ontvangt binnen de 14 kalenderdagen na het versturen van minstens één aangetekende ingebrekestelling. Het verschuldigde saldo wordt, in toepassing van art. 1152 BW, van rechtswege verhoogd met 15% van het factuurbedrag (inclusief BTW) met een minimum van 74,37 Euro.</p>
                <h3>Artikel 8:</h3>
                <p>Niet-betaling op de vervaldag geeft de verkoper het recht op het schorsen van alle lopende bestellingen, uitvoeringen en overeenkomsten en brengt tevens met zich mee dat nog niet vervallen facturen onmiddellijk opeisbaar worden zonder dat daartoe nog enige aanmaning vereist is.</p>
                <h3>Artikel 9:</h3>
                <p>Alle inningskosten, gerechtelijke of buitengerechtelijke aanmaningen inbegrepen, zijn steeds ten laste van de koper.</p>
                <h3>Artikel 10:</h3>
                <p>De verkoper behoudt zich het recht om de uitvoering van een bestelling te weigeren of uit te stellen zonder recht op schadeloosstelling vanwege de klant/koper.</p>
                <h3>Artikel 11:</h3>
                <p>Indien een klant een originele verpakking van een artikel opent of beschadigt, kan deze klant ertoe verplicht worden het betrokken artikel te kopen.</p>
                <h3>Artikel 12:</h3>
                <p>Voor omruiling of herstelling van aangekochte goederen krijgt de koper een “in herstelling” bewijs. Een artikel kan omgeruild worden binnen de 5 werkdagen na aankoop, voor zover dit toegestaan en/of technisch verantwoord is. Het artikel wordt omgeruild als het in nieuwe, onberispelijke staat wordt teruggebracht in de originele verpakking en met de originele aankoopfactuur. Bij herstellingen onder waarborg dient de klant de originele aankoopfactuur voor te leggen.</p>
                <h3>Artikel 13:</h3>
                <p>Tijdens de levering, installatie of herstelling van hard– en software kan de verkoper niet aansprakelijk gesteld worden voor elke schade die veroorzaakt is door externe elementen (elektriciteit, water/vocht, trillingen, transport indien niet verzorgd door Soletis bv, blikseminslag, …) en kan dus geen aanleiding geven tot het betalen van enige vorm van schadevergoeding door de verkoper.</p>
                <h3>Artikel 14:</h3>
                <p>Elke klacht in verband met een factuur, geleverde dienst of product dient schriftelijk en aangetekend te worden gedaan binnen de dertig dagen vanaf de verzending van de factuur, op straffe van niet-ontvankelijkheid. Eventuele reactie op een laattijdige klacht is steeds onder voorbehoud van alle rechten en zonder nadelige erkentenis en impliceert geen verzaking aan dit recht. In geen geval kan de financiële aansprakelijkheid van de verkoper de gefactureerde prijs overschrijden en kan geen aanleiding geven tot het betalen van enige andere vorm van schadevergoeding.</p>
                <h3>Artikel 15:</h3>
                <p>Het door de klant ter beschikking gestelde materiaal, voor het goed kunnen uitvoeren van de taken, wordt gedurende drie maanden na factuurdatum bewaard. Na deze termijn wordt het niet door de klant teruggevraagde materiaal vernietigd. Dit materiaal is voor geen enkel risico verzekerd en ontlast Soletis bv van alle verantwoordelijkheid.</p>
                <h3>Artikel 16:</h3>
                <p>De klant gaat ermee akkoord dat alle natuurlijke- en rechtspersoonsgegevens die de verkoper heeft ontvangen van de koper mogen verwerkt worden door Soletis bv in het kader van klantbeheer en direct marketing. De verwerking gebeurt in overeenstemming met de GDPR wet van 25 mei 2018. U hebt het recht om uw persoonlijke gegevens in te zien, deze te controleren en te vragen om uw persoonlijke gegevens te verbeteren, te wijzigen, te verwijderen of te blokkeren. U kunt uw toestemming op elk moment opnieuw intrekken. Soletis bv zal zijn persoonsgegevensbestand niet verkopen of doorgeven aan derde partijen, tenzij deze derde partijen contractueel verbonden zijn met Soletis bv of in opdracht van Soletis bv handelen. Hun toegang is contractueel beperkt tot de gegevens die ze nodig hebben voor de uitvoering van hun opdracht. De toegang tot en de beveiliging van persoonlijke gegevens is alleen voor de personeelsleden die bevoegd zijn voor de verwezenlijking ervan. Soletis bv treft bovendien uitgebreide technische en organisatorische maatregelen om de veiligheid en vertrouwelijkheid van uw persoonlijke gegevens te beschermen en herziet en verbetert deze maatregelen regelmatig voor zover nodig om aan de veranderende wettelijke bepalingen terzake te kunnen voldoen en/of om nieuwe verbeteringen op technologisch vlak te kunnen aanbrengen.</p>
                <h3>Artikel 17:</h3>
                <p>De klant verbindt zich ertoe alle eventuele wijzigingen met betrekking tot de gegeven inlichtingen (adres, activiteiten, …) onmiddellijk aan de verkoper mee te delen. Soletis bv kan niet aansprakelijk gesteld worden voor de schade die voortvloeit uit het niet doorgeven van wijzigingen of voor het geven van verkeerde inlichtingen.</p>
                <h3>Artikel 18:</h3>
                <p>Alle geleverde goederen blijven eigendom van de verkoper tot zij volledig zijn betaald.</p>
                <h3>Artikel 19:</h3>
                <p>De offerteprijs geldt alleen voor de opdracht vermeld in de offerte. Wijzigingen aan de oorspronkelijke offertegegevens door de klant zullen worden verrekend. De geldigheidsduur van een offerte bedraagt één maand, tenzij op de offerte anders vermeld staat. Een offerte is gratis als de klant nadien effectief de opdracht bevestigd heeft. Zoniet zijn de kosten voor het maken van de offerte ten laste van de klant.</p>
                <h3>Artikel 20:</h3>
                <p>De software, ontwikkeld om aan de behoeften van de klant te voldoen, wordt op maat van de opdrachtgever gemaakt. Software die vrij te koop is op de markt wordt geacht standaardsoftware te zijn. De opdrachtnemer blijft eigenaar van de knowhow die gebruikt of ontwikkeld werd voor de uitvoering van het contract. De opdrachtnemer is vrij deze knowhow te gebruiken voor een ander doel, bijvoorbeeld de realisatie van een soortgelijk project. De opdrachtnemer mag hiervoor zonder beperkingen de software, alsook de broncode die specifiek voor de klant ontwikkeld werd, gebruiken.</p>
                <h3>Artikel 21:</h3>
                <p>De klant kan geen enkel eigendomsrecht of intellectueel recht opeisen op standaardsoftware. Deze rechten zijn ofwel eigendom van de opdrachtnemer, ofwel van de ontwikkelaar, ofwel van een derde. De opdrachtnemer verzekert de klant dat hij gebruik mag maken van deze standaardsoftware.</p>
                <h3>Artikel 22:</h3>
                <p>De eigendom van de broncode blijft ten allen tijde eigendom van de opdrachtnemer bij software “op maat”. De broncode kan niet verkocht worden, noch gratis ter beschikking gesteld worden. Enkel met akkoord van beide partijen kan, tegen betaling, de broncode ter beschikking gesteld worden. Echter de eigendom blijft steeds van de opdrachtnemer. De in deze overeenkomst bepaalde prijs weerspiegelt een niet-exclusief en niet-overdraagbaar recht om gebruik te maken van de specifieke software, conform het doel van deze software. Deze licentie heeft geen territoriale beperkingen, noch beperkingen qua tijd.</p>
                <h3>Artikel 23:</h3>
                <p>Het is de klant/koper uitdrukkelijk verboden softwarelicenties te kopiëren, op straffe van het op zijn minst betalen van alle gekopieerde licenties. Dit heeft zowel betrekking op standaardsoftware als op specifiek op maat gemaakte software.</p>
                <h3>Artikel 24:</h3>
                <p>Elk van de partijen verbindt er zich toe de vertrouwelijke gegevens, inlichtingen, informatie, toepassingen, methodes en knowhow alsook eender welk soort document waarvan zij kennis kreeg tijdens de uitvoering van het contract niet te verspreiden of te communiceren, niet te laten verspreiden of te laten communiceren, niet direct of indirect te gebruiken, tenzij de andere partij hiervoor op voorhand schriftelijk de toestemming gaf. De verplichting tot vertrouwelijkheid die in die artikel voorzien is, geldt zolang de betreffende een vertrouwelijk karakter heeft, dus ook na het einde van het contract.</p>
                <h3>Artikel 25:</h3>
                <p>Gevallen van overmacht, en meer in het algemeen, alle omstandigheden die de uitvoering van de opdracht door de opdrachtnemer verhinderen, herleiden of vertragen ontheffen de opdrachtnemer van iedere aansprakelijkheid en stellen hem in de mogelijkheid om, naargelang de situatie, hetzij zijn verbintenissen in te korten, hetzij de overeenkomst te verbreken of de uitvoering ervan op te schorten, zonder dat hij tot enige schadevergoeding gehouden is.</p>
                <h3>Artikel 26:</h3>
                <p>Indien elektronische communicatie tussen de opdrachtnemer en de klant opgeslagen wordt op een vaste en onveranderbare informatiedrager, worden deze gegevens aanvaard door beide partijen als bewijsstukken.</p>
                <h3>Artikel 27:</h3>
                <p>Indien één van deze algemene voorwaarden om welke reden dan ook onwettig of onuitvoerbaar is, zullen de overige artikels van kracht blijven.</p>
                <h3>Artikel 28:</h3>
                <p>De algemene voorwaarden worden uitsluitend beheerst door het Belgische Recht. Ingeval van betwistingen zijn uitsluitend de rechtbanken van Oudenaarde bevoegd.</p>
            </div>

            <Footer />
        </>

    );
};


