import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./jobs.css";
import { Navbar } from "../../navbar/Navbar";
import { Footer } from "../../footer/Footer";

export function Jobs() {
    const [workItems, setWorkItems] = useState([]);
    const [filteredWorkItems, setFilteredWorkItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showLiquids, setShowLiquids] = useState(false); // State variable to manage liquid visibility
    const [selectedCountry, setSelectedCountry] = useState("All");
    const [selectedFunction, setSelectedFunction] = useState("All");
    const listRef = useRef(null);

    useEffect(() => {
        async function fetchWorkItemsWithIds() {
            try {
                // Fetch work items...
                setIsLoading(true); // Set loading to true initially

                // Fetch work items
                const responseIds = await fetch(
                    `${process.env.REACT_APP_RECAPTCHA_SITE_ADRESS}` + "/wit/wiql?api-version=6.0",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Basic ${btoa(`Basic:${process.env.REACT_APP_PATKEY}`)}`,
                        },
                        body: JSON.stringify({
                            query: "Select [System.Id] From WorkItems Where [System.WorkItemType] = 'Vacature'",
                        }),
                    }
                );

                if (!responseIds.ok) {
                    throw new Error("Failed to fetch work item IDs");
                }

                const dataIds = await responseIds.json();
                const ids = dataIds.workItems.map((item) => item.id);
                const responseWorkItems = await fetch(
                    `${process.env.REACT_APP_RECAPTCHA_SITE_ADRESS}` + `/wit/workitems?ids=${ids.join(",")}&api-version=7.1-preview.3`,
                    {
                        headers: {
                            Authorization: `Basic ${btoa(`Basic:${process.env.REACT_APP_PATKEY}`)}`,
                        },
                    }
                );

                if (!responseWorkItems.ok) {
                    throw new Error("Failed to fetch work items");
                }

                const dataWorkItems = await responseWorkItems.json();
                setWorkItems(dataWorkItems.value);
                setIsLoading(false);
                setShowLiquids(true); // Set showLiquids to true to start liquid animation
            } catch (error) {
                console.error("Error fetching work items:", error);
                setIsLoading(false);
            }
        }

        fetchWorkItemsWithIds();
    }, []);

    useEffect(() => {
        // Filter workItems based on selectedCountry and selectedFunction
        setFilteredWorkItems(
            workItems.filter(item => {
                const countryCriteria = selectedCountry === "All" || item.fields["Custom.Country"].toLowerCase().includes(selectedCountry.toLowerCase());
                const functionCriteria = selectedFunction === "All" ||
                    (selectedFunction === "Other" && !item.fields["Custom.Category"].toLowerCase().includes("front") && !item.fields["Custom.Category"].toLowerCase().includes("back")) ||
                    (selectedFunction === "Front-End Developer" && item.fields["Custom.Category"].toLowerCase().includes("front")) ||
                    (selectedFunction === "Back-End Developer" && item.fields["Custom.Category"].toLowerCase().includes("back"));
                return countryCriteria && functionCriteria;
            })
        );
    }, [workItems, selectedCountry, selectedFunction]);

    useEffect(() => {
        // Determine default country and function based on URL domain
        const domain = window.location.hostname;
        let defaultCountry = "All";
        let defaultFunction = "All";
        if (domain.endsWith(".be")) {
            defaultCountry = "Belgium";
        } else if (domain.endsWith(".rs")) {
            defaultCountry = "Serbia";
        }
        setSelectedCountry(defaultCountry);
        setSelectedFunction(defaultFunction);
    }, [workItems]);

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const getCountry = (item) => {
        const countryCode = item.fields["Custom.Country"].toLowerCase();
        if (countryCode.includes("belgium")) {
            return "Belgium";
        } else if (countryCode.includes("serbia")) {
            return "Serbia";
        } else {
            return "Unknown";
        }
    };

    const handleFunctionChange = (event) => {
        setSelectedFunction(event.target.value);
    };

    const scrollToJobsList = () => {
        listRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <Navbar />
            <div className="jobs_page">
                <div className={`jobsLiquid1 ${showLiquids ? 'animate' : ''}`}></div>
                <div className={`jobsLiquid2 ${showLiquids ? 'animate' : ''}`}></div>
                <div className="jobs_header">
                    <div className="jobs_header_content">
                        <h2 className="jobs_header_title">WE ARE RECRUITING!</h2>
                        <div className="jobs_header_description">
                            At Soletis bv, we're seeking skilled individuals to join our team of tech maestros. Apply now and embark on a journey where innovation meets expertise. Let's collaborate and code smiles into existence together!
                            <div />
                            <div className="jobs_header_exploreBtn" onClick={scrollToJobsList}>Explore open roles</div>
                        </div>
                    </div>
                </div>
                <div className="jobs_workingMeans">
                    <div className="jobs_workingMeans_title">Working at Soletis bv means:</div>
                    <div className="jobs_workingMeans_container">
                        <div className="jobs_workingMeans_content">
                            <div className="jobs_workingMeans_content_textContainer">
                                <p className="jobs_workingMeans_text">At Soletis, we simplify software solutions for clients, fostering success through innovation and customization.</p>
                                <br />
                                <p className="jobs_workingMeans_text">Our mission? Prioritize quality connections with clients, embracing diversity to ensure satisfaction and employee happiness.</p>
                                <br />
                                <p className="jobs_workingMeans_text">Efficiency and connectivity are key at Soletis. We streamline operations, making software accessible and cost-effective.</p>
                                <br />
                                <p className="jobs_workingMeans_text">Ready to innovate and collaborate? Join us in shaping the future of software solutions.</p> </div>
                            <div className="jobs_workingMeans_image"></div>
                        </div>
                    </div>
                </div>
                <div className="jobs_header_filterDiv">
                    <div>
                        <label>Country</label>
                        <select className="jobs_header_filterSelect" name="country" id="country" value={selectedCountry} onChange={handleCountryChange}>
                            <option value="All">All</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Serbia">Serbia</option>
                        </select>
                    </div>
                    <div>
                        <label>Function</label>
                        <select className="jobs_header_filterSelect" name="function" id="function" value={selectedFunction} onChange={handleFunctionChange}>
                            <option value="All">All</option>
                            <option value="Front-End Developer">Front-End Developer</option>
                            <option value="Back-End Developer">Back-End Developer</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>

                {isLoading ? (
                    <div className="jobs_loadingAnimation"></div>
                ) : (
                    <>
                        <ul className="jobs_list_ul" ref={listRef}>
                            {filteredWorkItems.map((item) => (
                                <div className="jobs_list" key={item.id}>
                                    <Link to={`/jobs/${item.id}`} className="jobs_list_item">
                                        <div className="jobs_list_itemUpper">
                                            <div className="jobs_list_item_info">
                                                {item.fields["System.Title"]}
                                            </div>
                                            <div className="jobs_list_itemBottom">
                                                {getCountry(item)}
                                            </div>
                                        </div>
                                        <div className="jobs_list_itemArrow">
                                            <svg width="26" height="30" viewBox="0 0 26 30">
                                                <path
                                                    id="Polygon_11"
                                                    data-name="Polygon 11"
                                                    d="M10.669,7.507a5,5,0,0,1,8.662,0L25.674,18.5A5,5,0,0,1,21.343,26H8.657a5,5,0,0,1-4.331-7.5Z"
                                                    transform="translate(26) rotate(90)"
                                                    fill="#faa61a"
                                                />
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </ul>
                        <div className="jobs_list_notFound">
                            <h2>Not found what you are looking for?</h2>
                            <p>No problem, we’re on the lookout for trailblazers—those who don’t just follow the path, but forge new ones.</p>
                            <Link to={"/jobs/openApplication"} className="jobs_list_notFoundButton" >Open Apply</Link>
                        </div>
                    </>

                )}

            </div>
            <Footer />
        </>
    );
}

