import React, { useState, useEffect, useRef } from "react";
import "./HomepageProducts.css";
import { NavLink } from "react-router-dom";

const productImages = {
  Dashboard: "/product_images/dashboard_bg.jpg",
  Audit: "/product_images/audit_bg.jpg",
  Reports: "/product_images/reports_bg.jpg",
  Webshop: "/product_images/webshop_bg.jpg",
  Extra: "/product_images/extra_bg.jpg",
  CRS: "/product_images/crs_bg.jpg", // Add image for CRS
};

export const HomepageProducts = () => {
  const [productActive, setProductActive] = useState("Dashboard");
  const homepageProductsRef = useRef(null);
  const liquidGraphic1Ref = useRef(null);
  const liquidGraphic2Ref = useRef(null);

  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("is-visible");
          observer.unobserve(entry.target);
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const elements = [liquidGraphic1Ref.current, liquidGraphic2Ref.current, homepageProductsRef.current];

    elements.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className="homepageProductsComponent">
      <div className="liquidGraphics">
        <div ref={liquidGraphic1Ref} className="liquidGraphic liquidGraphic-1 slideInLeft"></div>
        <div ref={liquidGraphic2Ref} className="liquidGraphic liquidGraphic-2 slideInRight"></div>
      </div>
      <div ref={homepageProductsRef} className="homepageProducts slideInBottom">
        <div className="homepageProducts_nav">
          <div className="homepageProducts_navdiv">
            <div className="homepageProducts_navdivTitle">Our Products</div>
            <ul>
              {["Dashboard", "Audit", "Reports", "Webshop", "Extra", "CRS"].map((product) => ( // Add CRS to the list
                <li
                  key={product}
                  className={productActive === product ? "active" : ""}
                  onClick={() => setProductActive(product)}
                >
                  {product}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="homepageProducts_container">
          <div className="homepageProducts_content">
            <h2>Soletis {productActive}</h2>
            <ul className="homepageProducts_contentSub">
              <li>Easy to use</li>
              <li>Out-of-the-box</li>
              <li>Structure First & Foremost</li>
              <li>Around the Clock</li>
            </ul>
            {productActive === "Dashboard" && (
              <>
                <div>
                  Create, schedule, and monitor processes effectively within your organization. Keep your business process automated with reports to track your efficiency.
                </div>
                <div>
                  <h3>Real-time tracking</h3>
                  <div>Monitor your business and reach your KPIs. Visualize business processes and identify bottlenecks.</div>
                </div>
                <div>
                  <h3>Job Automation</h3>
                  <div>Schedule and execute a broad spectrum of jobs to automate your business processes.</div>
                </div>
                <NavLink to={"/contact"} className="homepageProducts_button">
                  Get your Dashboard
                </NavLink>
              </>
            )}
            {productActive === "Audit" && (
              <>
                <div>
                  Throughout the years of earned knowledge and experience, we provide customer-specific auditing solutions. We provide insight into various domains of the IT landscape within your organization.
                </div>
                <ul className="homepageProducts_contentBullet">
                  <li>Various types of audit: performance, GDPR, crisis management, customer/IT liaison, future roads to follow/are we on the right track?</li>
                  <li>Many tools and experience which allow us to quickly respond</li>
                  <li>We carry out audits and can provide solutions</li>
                </ul>
                <NavLink to={"/contact"} className="homepageProducts_button">
                  Get your Audit
                </NavLink>
              </>
            )}
            {productActive === "Reports" && (
              <>
                <div>
                  Create, schedule, and monitor processes effectively within your organization. Keep your business process automated with reports to track your efficiency.
                </div>
                <div>
                  <h3>Rich graphics</h3>
                  <div>Various charts, such as scatter, bar, pie, and other charts. Tabular views and reports.</div>
                </div>
                <div>
                  <h3>Big data analytics</h3>
                  <div>Using big data, you can gain essential insights into your processes and see relevant details.</div>
                </div>
                <NavLink to={"/contact"} className="homepageProducts_button">
                  Get your Reports
                </NavLink>
              </>
            )}
            {productActive === "Webshop" && (
              <>
                <div>
                  Simplified and modern e-commerce platform. Take your business to the next level with a professional and affordable Soletis webshop.
                </div>
                <div>
                  <h3>Professional design</h3>
                  <div>Soletis will install a clear and attractive webshop for you. We will assist you with free advice.</div>
                </div>
                <div>
                  <h3>User friendly</h3>
                  <div>Soletis designs from the visitor's point of view with a focus on user-friendliness. Filters, an efficient search function, an adjustable shopping basket, and visible contact details are extremely important.</div>
                </div>
                <div>
                  <h3>Customer-oriented approach</h3>
                  <div>In order to work in a customer-oriented way, we try to analyze a Webshop visitor and the reason why they view the website. Knowing what the customer wants benefits your organization. We are constantly innovating, validating, and testing whether your webshop is attractive.</div>
                </div>
                <div>
                  <h3>Evaluation reports</h3>
                  <div>A Soletis webshop offers full control over your orders. We offer reports per customer, filterable by date, customer name, telephone number, and order status. Follow the orders per customer and see which products you sell the most. The delivery times report allows you to better organize your delivery service.</div>
                </div>
                <div>
                  <h3>Easy to adjust yourself</h3>
                  <div>A Soletis webshop is flexible and makes it possible to add, adjust, and remove your own products, product categories, subcategories, units of measurement (per piece/kg/box), prices, and images. After free training, you will get a complete overview of your articles. The images are automatically adapted to the device (mobile phone, laptop, etc.) on which they are displayed. Optionally, we can add a watermark to the images.</div>
                </div>
                <div>
                  <h3>Quality does not have to be expensive!</h3>
                  <div>Transparent prices, no strings attached. We offer a demo free of charge and without obligation. Free support up to 2x per month. One-time 499.00 euros (excl. VAT): installation and configuration + free training + 1st month free use.</div>
                </div>
                <NavLink to={"/contact"} className="homepageProducts_button">
                  Get your Webshop
                </NavLink>
              </>
            )}
            {productActive === "Extra" && (
              <>
                <div>
                  At Soletis, we pride ourselves on our versatile and adaptive approach to addressing unique business requirements. Beyond our core services of analyzing and automating administrative and technical workflows for SMEs and multinationals based in Gent, we understand that each business is distinct in its operations, challenges, and aspirations.
                  <br /><br />
                  That’s why we go the extra mile to offer tailored solutions that align seamlessly with your specific needs. Our experienced team collaborates closely with you to gain a deep understanding of your organization’s intricacies, objectives, and pain points. Armed with this insight, we craft customized strategies and solutions that not only optimize your workflows but also drive growth, efficiency, and innovation.
                  <br /><br />
                  Whether it’s integrating specialized tools, developing bespoke software, or designing unique process enhancements, we’re committed to shaping solutions that are as distinctive as your business itself. Our dedication to delivering results extends to even the most intricate or unconventional requirements.
                  <br /><br />
                  At Soletis, we’re not just about off-the-shelf solutions; we’re your partners in progress, ready to innovate and engineer precisely what your business needs to thrive in the competitive landscape. Let us transform your vision into reality with tailor-made solutions that empower your business to reach new heights.
                </div>
                <NavLink to={"/contact"} className="homepageProducts_button">
                  Get your Service
                </NavLink>
              </>
            )}
            {productActive === "CRS" && ( // Add new block for CRS
              <>
                <div>
                  At Soletis, we understand the importance of managing and reducing costs in today's competitive business environment. Our Cost Reduction Service (CRS) is designed to help your organization identify areas where you can save money without compromising on quality or efficiency.
                </div>
                <div>
                  <h3>Comprehensive Analysis</h3>
                  <div>Our team will perform a thorough analysis of your current expenses to identify cost-saving opportunities.</div>
                </div>
                <div>
                  <h3>Customized Strategies</h3>
                  <div>We provide tailored strategies that align with your business goals and operational needs.</div>
                </div>
                <div>
                  <h3>Continuous Monitoring</h3>
                  <div>Our CRS includes ongoing monitoring to ensure that savings are maintained and optimized over time.</div>
                </div>
                <div>
                  <h3>Expert Support</h3>
                  <div>Benefit from our extensive experience and expertise in cost management and reduction.</div>
                </div>
                <NavLink to={"/contact"} className="homepageProducts_button">
                  Get your CRS
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
