import React, { useEffect, useState } from "react";
import {  FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import "./footer.css";

export const Footer = () => {

  const [showBelgiumOffice, setShowBelgiumOffice] = useState(false);
  const [showSerbiaOffice, setShowSerbiaOffice] = useState(false);

  useEffect(() => {
    
    const domain = window.location.hostname.split('.').pop();
    const countryCodes = ['be', 'rs'];
    const countryCode = domain.slice(-2); 
  
    if (countryCodes.includes(countryCode)) {
      setShowBelgiumOffice(countryCode === 'be');
      setShowSerbiaOffice(countryCode === 'rs');
    } else {
      setShowBelgiumOffice(true); 
      setShowSerbiaOffice(false); 
    }
  }, []);
  

  return (

    <footer className="footer-container">
      {showBelgiumOffice && (
        <>
          <div className="company-info">
            <h3>Soletis bv</h3>
            <p>Offices:</p>
            <address>
              <p>Steenweg Deinze 150 bus 25 - 9810 Nazareth (Gent) Belgium</p>
              <p>Đure Jakšića 6, Smederevo 11300 Serbia</p>
            </address>
          </div>

          <div className="company-info-extra">
            <h3>Contact us</h3>
            <p>Phone: +3{process.env.REACT_APP_PN33}0 {process.env.REACT_APP_PN21}6 </p>
            <p>Mobile: +3{process.env.REACT_APP_PN14}6 {process.env.REACT_APP_PN98}8 </p>
            <p>Email: info@soletis.be</p>
            <p>Tax: BE 0878 048 453</p>
          </div>
        </>
      )}
      {showSerbiaOffice && (
        <>
          <div className="company-info">
            <h3>Soletis bv</h3>
            <p>Offices:</p>
            <address>
              <p>Đure Jakšića 6, Smederevo 11300 Serbia</p>
              <p>Steenweg Deinze 150 bus 25 - 9810 Nazareth (Gent) Belgium</p>
            </address>
          </div>

          <div className="company-info-extra">
            <h3>Contact us</h3>
            <p>Phone: +{process.env.REACT_APP_PN33} {process.env.REACT_APP_PN21}</p>
            <p>Mobile: +38{process.env.REACT_APP_PN34} {process.env.REACT_APP_PN34}87 </p>
            <p>Email: info@soletis.rs</p>
            <p>PIB: 114120305</p>
          </div>
        </>
      )}


      <div className="follow-us">
        <h3>Follow Us</h3>
        <div className="social-icons">
          {/* <a href="http://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebookSquare className="social-icon" />
          </a> */}
          <a href="https://www.instagram.com/soletisbv/" target="_blank" rel="noopener noreferrer">
            <FaInstagramSquare className="social-icon" />
          </a>
          {/* <a href="http://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitterSquare className="social-icon" />
          </a> */}
          <a href="https://www.linkedin.com/company/soletis-bv/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="social-icon" />
          </a>

        </div>
      </div>

      <div className="navigation">
        <h3>Navigation</h3>
        <ul>
          <li className="navigation_contact"><NavLink to="/contact">Contact us</NavLink></li>
          <li><NavLink to="/terms">Terms</NavLink></li>
          <li><NavLink to="/privacy">Privacy</NavLink></li>
          <li><NavLink to="/jobs">Jobs</NavLink></li>
        </ul>
      </div>

      <div className="footer_image-cr">
        <div className="footer_logoimage"></div>

        <div className="copyright">
          <p>&copy; 2024 Soletis bv. All rights reserved.</p>
        </div>
      </div>



    </footer>
  );
};
