import React, { useState } from "react";
import { Divide as Hamburger } from 'hamburger-react'
import "./navbar.css";
import { Link, NavLink } from "react-router-dom";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav className={menuOpen ? "navopen" : "nav"}>
      <Link to="/" className="title">

      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <Hamburger rounded toggled={menuOpen} toggle={setMenuOpen} />
      </div>
      <ul className={menuOpen ? "open" : "closed"}>
        <li>
          <NavLink to="/jobs">Jobs</NavLink>
        </li>
        <li>
          <NavLink to="/terms">Terms</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact us</NavLink>
        </li>
      </ul>
    </nav>
  );
};
