import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Navbar } from "../../navbar/Navbar";
import { Footer } from "../../footer/Footer";
import Applyform from "../Applyform/Applyform";
import './jobdetails.css'

export function JobsDetails() {
    const { id } = useParams();
    const [jobDetails, setJobDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_RECAPTCHA_SITE_ADRESS}/wit/workitems/${id}?api-version=7.1-preview.3`, {
                    headers: {
                        Authorization: `Basic ${btoa(`Basic:${process.env.REACT_APP_PATKEY}`)}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch job details");
                }

                const data = await response.json();

                // Check if the ticket exists
                if (!data) {
                    navigate("/");
                }

                setJobDetails(data);

                // Check if the work item belongs to the "SoletisContacts" project
                if (data.fields["System.AreaPath"] !== "SoletisContacts") {
                    navigate("/");
                }
            } catch (error) {
                console.error("Error fetching job details:", error);
                navigate("/");
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [id, navigate]);

    const renderHTML = (htmlString) => {
        return { __html: htmlString };
    };

    return (
        <>
            <Navbar />
            <div className="job-details-container">
                {isLoading ? (
                    <div className="jobs_loadingAnimation_container">
                        <div className="jobs_loadingAnimation"></div>
                    </div>

                ) : (
                    <>
                        <div className="job-details">
                            <div className="job-details_description">
                                <h2>{jobDetails?.fields?.["System.Title"]}</h2>
                                <p dangerouslySetInnerHTML={renderHTML(jobDetails?.fields?.["System.Description"])}></p>
                            </div>
                            <div className="job-details_apply">
                                <Applyform  title={jobDetails?.fields?.["System.Title"]}/>

                            </div>
                        </div>
                    </>
                )}
            </div>
            <Footer />
        </>
    );
}
